export const logo = require('./lonestarlogo.jpeg')
export const cypress5 = require('./Carpet/cypress5.jpeg')
export const cypress6 = require('./Carpet/cypress6.jpeg')
export const cypress7 = require('./Carpet/cypress7.jpeg')
export const cypress9 = require('./Carpet/cypress9.jpeg')
export const cypress10 = require('./Carpet/cypress10.jpeg')
export const dorothy1 = require('./Carpet/dorothy1.jpeg')
export const dorothy2 = require('./Carpet/dorothy2.jpeg')
export const dorothy3 = require('./Carpet/dorothy3.jpeg')
export const dorothy4 = require('./Carpet/dorothy4.jpeg')
export const dorothy5 = require('./Carpet/dorothy5.jpeg')
export const dorothy6 = require('./Carpet/dorothy6.jpeg')
export const dorothyFirst2 = require('./Carpet/dorothyFirst2.jpeg')
export const dorothyFirst10 = require('./Carpet/dorothyFirst10.jpeg')
export const mansion14 = require('./Carpet/mansion14.jpeg')
export const mansion15 = require('./Carpet/mansion15.jpeg')
export const mansion16 = require('./Carpet/mansion16.jpeg')
export const mansion17 = require('./Carpet/mansion17.jpeg')
export const mansion18 = require('./Carpet/mansion18.jpeg')
export const mansion19 = require('./Carpet/mansion19.jpeg')
export const memorial3 = require('./Carpet/memorial3.jpeg')
export const memorial6 = require('./Carpet/memorial6.jpeg')
export const granite1 = require('./Granite/granite1.jpeg')
export const granite3 = require('./Granite/granite3.jpeg')
export const granite4 = require('./Granite/granite4.jpeg')
export const granite5 = require('./Granite/granite5.jpeg')
export const granite6 = require('./Granite/granite6.jpeg')
export const sam_granite1 = require('./Granite/sam_granite1.jpeg')
export const sam_granite2 = require('./Granite/sam_granite2.jpeg')
export const sam_granite3 = require('./Granite/sam_granite3.jpeg')
export const sam_granite4 = require('./Granite/sam_granite4.jpeg')
export const sam_granite5 = require('./Granite/sam_granite5.jpeg')
export const taiwo2 = require('./LVT/Taiwo2.jpeg')
export const taiwo3 = require('./LVT/Taiwo3.jpeg')
export const taiwo4 = require('./LVT/Taiwo4.jpeg')
export const taiwo5 = require('./LVT/Taiwo5.jpeg')
export const taiwo6 = require('./LVT/Taiwo6.jpeg')
export const taiwo7 = require('./LVT/Taiwo7.jpeg')
export const taiwo8 = require('./LVT/Taiwo8.jpeg')
export const avery_lvt1 = require('./LVT/avery_lvt1.jpg')
export const avery_lvt2 = require('./LVT/avery_lvt2.jpg')
export const avery_lvt3 = require('./LVT/avery_lvt3.jpg')
export const avery_lvt4 = require('./LVT/avery_lvt4.jpg')
export const avery_lvt5 = require('./LVT/avery_lvt5.jpg')
export const avery_lvt6 = require('./LVT/avery_lvt6.jpg')
export const avery_lvt7 = require('./LVT/avery_lvt7.jpg')
export const avery_lvt8 = require('./LVT/avery_lvt8.jpg')
export const woodtile1 = require('./LVT/woodtile1.jpg')
export const woodtile2 = require('./LVT/woodtile2.jpg')
export const woodtile3 = require('./LVT/woodtile3.jpg')
export const woodtile4 = require('./LVT/woodtile4.jpg')
export const woodtile5 = require('./LVT/woodtile5.jpg')
export const bev_granite1 = require('./Granite/bev_granite1.jpeg')
export const bev_granite2 = require('./Granite/bev_granite2.jpeg')
export const bev_granite3 = require('./Granite/bev_granite3.jpeg')
export const mr_julio2 = require('./KBFR/mr_julio2.jpg')
export const mr_steven_floor1 = require('./KBFR/mr_steven_floor1.jpg')
export const mr_steven_floor2 = require('./KBFR/mr_steven_floor2.jpg')
export const mr_steven_floor3 = require('./KBFR/mr_steven_floor3.jpg')
export const mr_steven_paint = require('./KBFR/mr_steven_paint.jpg')
export const mr_steven_paint2 = require('./KBFR/mr_steven_paint2.jpg')
export const ms_linda1 = require('./KBFR/ms_linda1.JPG')
export const ms_linda2 = require('./KBFR/ms_linda2.JPG')
export const ms_linda3 = require('./KBFR/ms_linda3.JPG')
export const ms_linda4 = require('./KBFR/ms_linda4.JPG')
export const ms_linda7 = require('./KBFR/ms_linda7.JPG')
export const ms_linda10 = require('./KBFR/ms_linda10.JPG')
export const ms_linda11 = require('./KBFR/ms_linda11.JPG')
export const ms_linda12 = require('./KBFR/ms_linda12.JPG')
export const ms_linda13 = require('./KBFR/ms_linda13.JPG')
export const ms_linda17 = require('./KBFR/ms_linda17.JPG')
export const ms_linda18 = require('./KBFR/ms_linda18.JPG')
export const ms_linda20 = require('./KBFR/ms_linda20.JPG')
export const ms_ruth1 = require('./KBFR/ms_ruth1.jpg')
export const ms_ruth5 = require('./KBFR/ms_ruth5.jpg')
export const commercial1 = require('./KBFR/commercial1.jpeg')
export const commercial4 = require('./KBFR/commercial4.jpeg')
export const lvt_risers = require('./KBFR/lvt-risers.jpeg')
export const genCon = require('./KBFR/gc.jpg')